import React, { useState, useEffect } from "react";
import "../../../home/home.css";
import api from "../../../api/api";
import config from "../../../config";

import NoImage from '../../../assets/images/noimage.jpg';

const ClientData = () => {

    const [clients, setClient] = useState([]);
    useEffect(() => {
        api.get(`/clients/{pages}`).then((res) => {
            if (res.status === 200) {
                setClient(res.data.data);
            }
        });
    }, []);
    
    return (
        <>
            {
                clients.map((item, idx) => (

                    <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s" key={idx}>
                        <div className="valuedclients center our-valued-clients box1">
                            <a href={item.hyperlink} target="_blank" rel="noopener noreferrer" className='tooltip-container'>
                                <img 
                                    className="img-fluids" 
                                    alt={item.company_name}
                                    src={item.company_logo ? `${config.IMAGE_BASE_PATH}${item.company_logo}` : NoImage}
                                />
                                {item.company_logo ? '' : (
                                    <span className="tooltip-text">{item.company_name}</span>
                                )}
                            </a>
                        </div>
                    </div>
                ))
            }
        </>
    );
};

export default ClientData;
