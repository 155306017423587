import React from "react"
import TestimonialPageDetails from './TestimonialPageDetails'

const TestimonialPage = () => {
    return (
        <>
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Testimonial</span> 
                        <span className='text-center' style={{ paddingTop:'10px', fontSize:'22px' }}>
                            {/* Every visual task is an opportunity to engage with audiences */}
                        </span>
                    </p>            
                </div>
            </section>

            <section id="testimonials" className="testimonials">
                <TestimonialPageDetails />
            </section>
        </>
    )
}
export default TestimonialPage