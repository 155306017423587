import React from 'react';
import '../home.css';

import a2Hosting from '../../assets/img/partners/a2Hosting.webp';
import nameCheap from '../../assets/img/partners/namecheap-logo.webp';
import microsoft from '../../assets/img/partners/microsoft.webp';
import microsoftPartner from '../../assets/img/partners/microsoft-partner.webp';
import redHat from '../../assets/img/partners/redHat.webp';
import solutionSquad from '../../assets/img/partners/solutionSquad.webp';


const TechnologyPartner = () => {
    return (
        <>
            <div >
                <div className="col-lg-12 text-center">
                    <header className="section-header">
                        <p className="blue-un">Our Technology Partners</p>
                    </header>
                </div>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                
                    <div className="row gy-4">
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#" rel="noreferrer">
                                    <img className="img-fluids" src={a2Hosting} alt="a2Hosting" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#" rel="noreferrer">
                                    <img className="img-fluids" src={nameCheap} alt="nameCheap" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#" rel="noreferrer">
                                    <img className="img-fluids" src={microsoft} alt="microsoft" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#" rel="noreferrer">
                                    <img className="img-fluids" src={microsoftPartner} alt="microsoftPartner" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#" rel="noreferrer">
                                    <img className="img-fluids" src={redHat} alt="redHat" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#" rel="noreferrer">
                                    <img className="img-fluids" src={solutionSquad} alt="solutionSquad" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TechnologyPartner;