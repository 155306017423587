import React from "react"
import '../../../home/home.css'

import lutfurHasan from '../../../assets/img/testimonials/lutfurHasan.jpg';
import jessyHudson from '../../../assets/img/testimonials/jessyHudson.jpg';
import robinThicke from '../../../assets/img/testimonials/robinThicke.jpg';
import stephenieMealy from '../../../assets/img/testimonials/stephenieMealy.jpg';
import rubelKhan from '../../../assets/img/testimonials/rubelKhan.jpg';


const TestimonialPageDetails = () => {
    return (
        <> 
            <div className=''>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                    <div className="testimonials-slider row" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                        <div className="team col-lg-4">
                            <div className="testimonial-item">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={lutfurHasan} className="img-fluid" alt="lutfurHasan" />
                                        {/* <div className="social">
                                            <a href="#a"><i className="bi bi-twitter"></i></a>
                                            <a href="#a"><i className="bi bi-facebook"></i></a>
                                            <a href="#a"><i className="bi bi-instagram"></i></a>
                                            <a href="#a"><i className="bi bi-linkedin"></i></a>
                                        </div> */}
                                    </div>
                                    <div className="member-info">
                                        <h4>Lutfur Hasan</h4>
                                        <span>Founder and Director</span>
                                        <p>I have developed a CRM software by a Web Solution Firm. They are a highly technical and deadline oriented team. Team maintained good communication with me. Highly recommended.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="team col-lg-4">
                            <div className="testimonial-item" >
                                <div className="member">
                                    <div className="member-img">
                                        <img src={jessyHudson} className="img-fluid" alt="jessyHudson" />
                                    </div>
                                    <div className="member-info">
                                        <h4>Jessy Hudson</h4>
                                        <span>Team Manager</span>
                                        <p>5 Star service. Very friendly and professional team. They took my suggestions on board. It was a very difficult site and they did the job amicably with a good price. We highly recommend it.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="team col-lg-4">
                            <div className="testimonial-item">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={robinThicke} className="img-fluid" alt="robinThicke" />
                                    </div>
                                    <div className="member-info">
                                        <h4>Robin Thicke</h4>
                                        <span>Managing Director</span>
                                        <p>We are delighted with the service of Web Solution Firm. Our Company is based in Auckland, NZ. We have been using entire software and web service from Web solution Firm since last 5 years.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="team col-lg-4">
                            <div className="testimonial-item">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={stephenieMealy} className="img-fluid" alt="stephenieMealy" />
                                    </div>
                                    <div className="member-info">
                                        <h4>Stephenie Mealy</h4>
                                        <span>Chief Technology Officer</span>
                                        <p>Great job done by Web Solution Firm, excellent communication, friendly professional team. Masum Billah from Web Solution Firm was able to develop the entire system in three months. We highly recommend his service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="team col-lg-4">
                            <div className="testimonial-item">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={rubelKhan} className="img-fluid" alt="rubelKhan" />
                                    </div>
                                    <div className="member-info">
                                        <h4>Rubel Khan</h4>
                                        <span>Chief Executive Officer</span>
                                        <p>Web Solution Firm has designed a beautiful website for my company. Design couldn’t be better than what the design team came up with. Good communication and on time delivery, recoemmended.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
        </>
    )
}
export default TestimonialPageDetails